/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { CommandExamplesResponseSchema } from '../models/CommandExamplesResponseSchema';
import type { CommandLogsResponseSchema } from '../models/CommandLogsResponseSchema';
import type { CommandRunRequestSchema } from '../models/CommandRunRequestSchema';
import type { CommandRunResponseSchema } from '../models/CommandRunResponseSchema';
import type { CommandSchema } from '../models/CommandSchema';
import type { CommandsResponseSchema } from '../models/CommandsResponseSchema';
import type { EditableCommandSchema } from '../models/EditableCommandSchema';
import type { GenerationLogResponseSchema } from '../models/GenerationLogResponseSchema';
import type { GenerationRequestSchema } from '../models/GenerationRequestSchema';
import type { GenerationResponseSchema } from '../models/GenerationResponseSchema';
import type { GenericResults_GiveT_ } from '../models/GenericResults_GiveT_';
import type { GenericResults_NeedT_ } from '../models/GenericResults_NeedT_';
import type { GenericResults_ScoredOption_AppTypeActionSubset__ } from '../models/GenericResults_ScoredOption_AppTypeActionSubset__';
import type { GenericResults_ScoredOption_AuthenticationSubset__ } from '../models/GenericResults_ScoredOption_AuthenticationSubset__';
import type { GenericResults_ScoredOption_ParamsMetaSubset__ } from '../models/GenericResults_ScoredOption_ParamsMetaSubset__';
import type { GenericResults_ThinAccount_ } from '../models/GenericResults_ThinAccount_';
import type { LLMplateRenderRequestSchema } from '../models/LLMplateRenderRequestSchema';
import type { LLMplateRenderResponseSchema } from '../models/LLMplateRenderResponseSchema';
import type { MakeTestCaseResponseSchema } from '../models/MakeTestCaseResponseSchema';
import type { MessagesResponseSchema } from '../models/MessagesResponseSchema';
import type { MutateThreadRequestSchema } from '../models/MutateThreadRequestSchema';
import type { SuccessResponseSchema } from '../models/SuccessResponseSchema';
import type { ThreadResponseSchema } from '../models/ThreadResponseSchema';
import type { ThreadsResponseSchema } from '../models/ThreadsResponseSchema';
import type { TokenResponseSchema } from '../models/TokenResponseSchema';
import type { TypeShuntResponseSchema } from '../models/TypeShuntResponseSchema';
import type { UserMessageSchema } from '../models/UserMessageSchema';
import type { UserSchema } from '../models/UserSchema';
import type { ZDLGenerationRequestSchema } from '../models/ZDLGenerationRequestSchema';
import type { ZDLPPMutationRequestSchema } from '../models/ZDLPPMutationRequestSchema';
import type { ZDLStepRequestSchema } from '../models/ZDLStepRequestSchema';
import type { ZdlGenerationResponseSchema } from '../models/ZdlGenerationResponseSchema';

export class DefaultService {
  /**
   * Me
   * @returns UserSchema OK
   * @throws ApiError
   */
  public static me(): CancelablePromise<UserSchema> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/me/',
    });
  }

  /**
   * Generate
   * @param requestBody
   * @returns GenerationResponseSchema OK
   * @throws ApiError
   */
  public static generate(
    requestBody: GenerationRequestSchema
  ): CancelablePromise<GenerationResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/generate/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Generate Streaming Get
   * Get an SSE stream of generation results. Same response shape as /generate/
   * but as a stream:
   *
   * HTTP/1.1 200 OK
   * Transfer-Encoding: chunked
   * Connection: Transfer-Encoding
   * Content-Type: text/event-stream
   *
   * event: data
   * data: {"generation_id":"01GQ2P5N1343SM477MCVZNBT4D","prompt_str":"Hello world","completions_str":["!"],"best_completion_str":"!"}
   *
   * event: data
   * data: {"generation_id":"01GQ2P5N1343SM477MCVZNBT4D","prompt_str":"Hello world","completions_str":["!\n"],"best_completion_str":"!\n"}
   *
   * event: data
   * [...etc...]
   * @param prompt
   * @param provider
   * @param scoringMethod
   * @param model
   * @param maxTokens
   * @param numCompletions
   * @param temperature
   * @param stopSequences
   * @param topP
   * @param presencePenalty
   * @param frequencyPenalty
   * @param logitBias
   * @param extra
   * @param continueFromGenerationLogId
   * @returns any OK
   * @throws ApiError
   */
  public static generateStreamingGet(
    prompt: string,
    provider: 'openai' | 'cohere' | 'huggingface' | 'anthropic' = 'openai',
    scoringMethod: string = 'summed_average',
    model: string = 'text-davinci-003',
    maxTokens: number = 100,
    numCompletions: number = 1,
    temperature: number = 0.8,
    stopSequences?: Array<string>,
    topP?: number,
    presencePenalty?: number,
    frequencyPenalty?: number,
    logitBias?: Record<string, number>,
    extra?: any,
    continueFromGenerationLogId?: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/generate/streaming/',
      query: {
        provider: provider,
        scoring_method: scoringMethod,
        model: model,
        max_tokens: maxTokens,
        num_completions: numCompletions,
        temperature: temperature,
        stop_sequences: stopSequences,
        top_p: topP,
        presence_penalty: presencePenalty,
        frequency_penalty: frequencyPenalty,
        logit_bias: logitBias,
        extra: extra,
        continue_from_generation_log_id: continueFromGenerationLogId,
        prompt: prompt,
      },
    });
  }

  /**
   * Llmplate Render
   * This is synchronous, but it's not a big deal.
   * @param requestBody
   * @returns LLMplateRenderResponseSchema OK
   * @throws ApiError
   */
  public static llmplateRender(
    requestBody: LLMplateRenderRequestSchema
  ): CancelablePromise<LLMplateRenderResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/llmplate/render/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Llmplate Render Streaming Get
   * @param template
   * @param additionalRenderers
   * @param allowFallbackRenderer
   * @returns any OK
   * @throws ApiError
   */
  public static llmplateRenderStreamingGet(
    template: string,
    additionalRenderers?: Record<string, string>,
    allowFallbackRenderer: boolean = true
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/llmplate/render/streaming/',
      query: {
        template: template,
        additional_renderers: additionalRenderers,
        allow_fallback_renderer: allowFallbackRenderer,
      },
    });
  }

  /**
   * Zap Suggestions Stream Get
   * @param automationGoal
   * @param countTopGuidedRecipes
   * @param countGeneratedGuidedRecipes
   * @returns any OK
   * @throws ApiError
   */
  public static zapSuggestionsStreamGet(
    automationGoal: string,
    countTopGuidedRecipes: number = 3,
    countGeneratedGuidedRecipes: number = 3
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/zap/suggestions/streaming/',
      query: {
        automation_goal: automationGoal,
        count_top_guided_recipes: countTopGuidedRecipes,
        count_generated_guided_recipes: countGeneratedGuidedRecipes,
      },
    });
  }

  /**
   * Zdl Generate Streaming Get
   * @param description
   * @returns any OK
   * @throws ApiError
   */
  public static zdlGenerateStreamingGet(description: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/zdl/generate/streaming/',
      query: {
        description: description,
      },
    });
  }

  /**
   * Zdl Generate
   * @param requestBody
   * @returns ZdlGenerationResponseSchema OK
   * @throws ApiError
   */
  public static zdlGenerate(
    requestBody: ZDLGenerationRequestSchema
  ): CancelablePromise<ZdlGenerationResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/zdl/generate/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get Commands
   * @returns CommandsResponseSchema OK
   * @throws ApiError
   */
  public static getCommands(): CancelablePromise<CommandsResponseSchema> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/commands/',
    });
  }

  /**
   * Create Command
   * @param requestBody
   * @returns CommandSchema OK
   * @throws ApiError
   */
  public static createCommand(
    requestBody: EditableCommandSchema
  ): CancelablePromise<CommandSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/commands/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get Command
   * @param commandId
   * @returns CommandSchema OK
   * @throws ApiError
   */
  public static getCommand(commandId: string): CancelablePromise<CommandSchema> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/commands/{command_id}/',
      path: {
        command_id: commandId,
      },
    });
  }

  /**
   * Update Command
   * @param commandId
   * @param requestBody
   * @returns CommandSchema OK
   * @throws ApiError
   */
  public static updateCommand(
    commandId: string,
    requestBody: EditableCommandSchema
  ): CancelablePromise<CommandSchema> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v1/commands/{command_id}/',
      path: {
        command_id: commandId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Delete Command
   * @param commandId
   * @returns any OK
   * @throws ApiError
   */
  public static deleteCommand(commandId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v1/commands/{command_id}',
      path: {
        command_id: commandId,
      },
    });
  }

  /**
   * Run Command
   * @param commandId
   * @param requestBody
   * @returns CommandRunResponseSchema OK
   * @throws ApiError
   */
  public static runCommand(
    commandId: string,
    requestBody: CommandRunRequestSchema
  ): CancelablePromise<CommandRunResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/commands/{command_id}/run/',
      path: {
        command_id: commandId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Generate Examples
   * @param commandId
   * @returns CommandExamplesResponseSchema OK
   * @throws ApiError
   */
  public static generateExamples(
    commandId: string
  ): CancelablePromise<CommandExamplesResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/commands/{command_id}/generate-examples/',
      path: {
        command_id: commandId,
      },
    });
  }

  /**
   * Deploy Command
   * @param commandId
   * @returns SuccessResponseSchema OK
   * @throws ApiError
   */
  public static deployCommand(commandId: string): CancelablePromise<SuccessResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/commands/{command_id}/deploy/',
      path: {
        command_id: commandId,
      },
    });
  }

  /**
   * Copy Command
   * @param commandId
   * @returns CommandSchema OK
   * @throws ApiError
   */
  public static copyCommand(commandId: string): CancelablePromise<CommandSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/commands/{command_id}/copy/',
      path: {
        command_id: commandId,
      },
    });
  }

  /**
   * Get Command Logs
   * @param commandId
   * @returns CommandLogsResponseSchema OK
   * @throws ApiError
   */
  public static getCommandLogs(commandId: string): CancelablePromise<CommandLogsResponseSchema> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/commands/{command_id}/logs/',
      path: {
        command_id: commandId,
      },
    });
  }

  /**
   * Get Chat Threads
   * @returns ThreadsResponseSchema OK
   * @throws ApiError
   */
  public static getChatThreads(): CancelablePromise<ThreadsResponseSchema> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/threads/',
    });
  }

  /**
   * Create Chat Thread
   * @param requestBody
   * @returns SuccessResponseSchema OK
   * @throws ApiError
   */
  public static createChatThread(
    requestBody: MutateThreadRequestSchema
  ): CancelablePromise<SuccessResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/threads/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get Chat Thread
   * @param threadId
   * @returns ThreadResponseSchema OK
   * @throws ApiError
   */
  public static getChatThread(threadId: string): CancelablePromise<ThreadResponseSchema> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/threads/{thread_id}/',
      path: {
        thread_id: threadId,
      },
    });
  }

  /**
   * Update Chat Thread
   * @param threadId
   * @param requestBody
   * @returns ThreadResponseSchema OK
   * @throws ApiError
   */
  public static updateChatThread(
    threadId: string,
    requestBody: MutateThreadRequestSchema
  ): CancelablePromise<ThreadResponseSchema> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v1/threads/{thread_id}/',
      path: {
        thread_id: threadId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Delete Chat Thread
   * @param threadId
   * @returns any OK
   * @throws ApiError
   */
  public static deleteChatThread(threadId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v1/threads/{thread_id}/',
      path: {
        thread_id: threadId,
      },
    });
  }

  /**
   * Get Chat Messages
   * If you want to get messages from main chat, provide a thread_id of 00000000000000000000000000.
   * @param threadId
   * @returns MessagesResponseSchema OK
   * @throws ApiError
   */
  public static getChatMessages(threadId: string): CancelablePromise<MessagesResponseSchema> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/messages/{thread_id}/',
      path: {
        thread_id: threadId,
      },
    });
  }

  /**
   * Create Chat Message
   * If you want to post to main chat, provide a thread_id of 00000000000000000000000000.
   * @param threadId
   * @param requestBody
   * @returns MessagesResponseSchema OK
   * @throws ApiError
   */
  public static createChatMessage(
    threadId: string,
    requestBody: UserMessageSchema
  ): CancelablePromise<MessagesResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/messages/{thread_id}/',
      path: {
        thread_id: threadId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Make Test Case
   * Make a test case, if you are a super user.
   * @param threadId
   * @param messageId
   * @returns MakeTestCaseResponseSchema OK
   * @throws ApiError
   */
  public static makeTestCase(
    threadId: string,
    messageId: string
  ): CancelablePromise<MakeTestCaseResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/messages/{thread_id}/{message_id}/make-test-case/',
      path: {
        thread_id: threadId,
        message_id: messageId,
      },
    });
  }

  /**
   * Get Message Generation Log
   * Get the generation log for a message, if you are a super user.
   * @param threadId
   * @param messageId
   * @returns GenerationLogResponseSchema OK
   * @throws ApiError
   */
  public static getMessageGenerationLog(
    threadId: string,
    messageId: string
  ): CancelablePromise<GenerationLogResponseSchema> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/messages/{thread_id}/{message_id}/generation-log/',
      path: {
        thread_id: threadId,
        message_id: messageId,
      },
    });
  }

  /**
   * Get Threads Token
   * Get a token for Firebase for the current user and view.
   * @returns TokenResponseSchema OK
   * @throws ApiError
   */
  public static getThreadsToken(): CancelablePromise<TokenResponseSchema> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/token/threads/',
    });
  }

  /**
   * Get Zdlpp Token
   * Get a token for Firebase for the current user and view.
   * @returns TokenResponseSchema OK
   * @throws ApiError
   */
  public static getZdlppToken(): CancelablePromise<TokenResponseSchema> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/token/zdlpp/',
    });
  }

  /**
   * Get Type Shunt
   * Get a list of test cases for the type shunt.
   * @returns TypeShuntResponseSchema OK
   * @throws ApiError
   */
  public static getTypeShunt(): CancelablePromise<TypeShuntResponseSchema> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/type-shunt/',
    });
  }

  /**
   * Zdlpp Create
   * @returns any OK
   * @throws ApiError
   */
  public static zdlppCreate(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/zdlpp/',
    });
  }

  /**
   * Zdlpp Mutate
   * @param zdlppId
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public static zdlppMutate(
    zdlppId: string,
    requestBody: ZDLPPMutationRequestSchema
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/zdlpp/{zdlpp_id}/',
      path: {
        zdlpp_id: zdlppId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Zdlpp Delete
   * @param zdlppId
   * @returns any OK
   * @throws ApiError
   */
  public static zdlppDelete(zdlppId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v1/zdlpp/{zdlpp_id}/',
      path: {
        zdlpp_id: zdlppId,
      },
    });
  }

  /**
   * List Accounts Endpoint
   * @returns GenericResults_ThinAccount_ OK
   * @throws ApiError
   */
  public static listAccountsEndpoint(): CancelablePromise<GenericResults_ThinAccount_> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/accounts/',
    });
  }

  /**
   * Search Apps Actions Endpoint
   * @param query
   * @param onlyConnected
   * @returns GenericResults_ScoredOption_AppTypeActionSubset__ OK
   * @throws ApiError
   */
  public static searchAppsActionsEndpoint(
    query: string,
    onlyConnected: boolean = true
  ): CancelablePromise<GenericResults_ScoredOption_AppTypeActionSubset__> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/options/apps-actions/',
      query: {
        query: query,
        only_connected: onlyConnected,
      },
    });
  }

  /**
   * Get Authentications Endpoint
   * @param appQueryOrSelectedApi
   * @param query
   * @returns GenericResults_ScoredOption_AuthenticationSubset__ OK
   * @throws ApiError
   */
  public static getAuthenticationsEndpoint(
    appQueryOrSelectedApi: string,
    query?: string
  ): CancelablePromise<GenericResults_ScoredOption_AuthenticationSubset__> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/options/authentications/',
      query: {
        app_query_or_selected_api: appQueryOrSelectedApi,
        query: query,
      },
    });
  }

  /**
   * List Choices Endpoint
   * @param query
   * @param paramKey
   * @param requestBody
   * @returns GenericResults_ScoredOption_ParamsMetaSubset__ OK
   * @throws ApiError
   */
  public static listChoicesEndpoint(
    query: string,
    paramKey: string,
    requestBody: ZDLStepRequestSchema
  ): CancelablePromise<GenericResults_ScoredOption_ParamsMetaSubset__> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/options/choices/',
      query: {
        query: query,
        param_key: paramKey,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * List Needs Endpoint
   * @param requestBody
   * @returns GenericResults_NeedT_ OK
   * @throws ApiError
   */
  public static listNeedsEndpoint(
    requestBody: ZDLStepRequestSchema
  ): CancelablePromise<GenericResults_NeedT_> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/needs/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * List Gives Endpoint
   * @param requestBody
   * @returns GenericResults_GiveT_ OK
   * @throws ApiError
   */
  public static listGivesEndpoint(
    requestBody: ZDLStepRequestSchema
  ): CancelablePromise<GenericResults_GiveT_> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/gives/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
